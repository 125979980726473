<template>
  <div class="LoyiSelect" :class="disabled?'disabled':''" :style="'width:'+width+'px'" @click.stop="showUl()">
    <div class="LoyiSelect_a">
      <span v-if="selectData.length==0">请选择</span>
      <div class="LoyiSelect_ico" v-for="(item,i) in selectData" :key="item">{{item.name}}<i class="icon-x" @click.stop="DelRd(i)"></i></div>
    </div>
    <div class="LoyiSelect_b" :style="'width:'+width+'px'" v-show="LoyiSelectUl" @click.stop>
      <div class="LoyiSelect_b_1"><input type="text" v-model="ser" @keyup="getser()"/></div>
      <div class="LoyiSelect_b_2">
        <ul v-if="filterData.length">
          <li v-for="(item,i) in filterData" :class="getHover(item.id)" :key="i" @click.stop="substr(item)">{{item.name}}</li>
        </ul>
        <div class="LoyiSelectNoData" v-else>暂无数据</div>
      </div>
    </div>
    <div class="meg_all" @click.stop v-if="onWindow">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LoyiSelect',
  props: {
    width:{
      type:Number,
      default:205,
    },
    Uldata:{
      type:Array,
      default:()=>[],
    },
    sopIds: {
      type:String,
      default:'',
    },
    openWindow:Boolean,
    disabled:Boolean,
  },
  data () {
    return {
      onWindow:false,
      filterData:this.Uldata,
      LoyiSelectUl:false,
      ser: '',
      selectData:[],
      ids: [],
    }
  },
  created () {
    this.init()
  },
  mounted () {
    document.addEventListener('click',()=>{
      if(this.LoyiSelectUl) this.LoyiSelectUl=false
    })
  },
  methods: {
    closdMeg(){
      this.onWindow=false
    },
    getHover(id){
      if(this.selectData.findIndex(e=>e.id==id)>=0) return 'hover'
      else return ''
    },
    init(){
      if(this.sopIds) {
        this.ids=this.sopIds.split(',')
        this.selectData=this.Uldata.filter(e=>{
          return(this.ids.includes(e.id+''))
        })
      }else{
        this.ids=[]
        this.selectData=[]
      }
    },
    DelRd (i) {
      let delData=this.selectData.splice(i,1)
      this.$emit("resData",{thisData:this.selectData,delData:delData[0]})
    },
    showUl () {
      if(!this.disabled){
        if(this.openWindow){ 
          this.onWindow=true
          this.$emit("windowOpen")
        }
        else this.LoyiSelectUl=!this.LoyiSelectUl
      }
    },
    substr (item) {
      let index = this.selectData.findIndex(e=>e.id==item.id)
      let newData=false,delData=false
      if(index<0){
        this.selectData.push(item)
        newData=item
      }else{
        delData=this.selectData.splice(index,1)
      }
      this.$emit("resData",{thisData:this.selectData,delData:delData[0],newData})
    },
    getser () {
      this.filterData = this.Uldata.filter((x) => {
        return x.name.indexOf(this.ser) >= 0
      })
    }
  },
  watch: {
    Uldata (newnew) {
      this.filterData = newnew
    },
    sopIds () {
      this.init()
    }
  }
}
</script>
