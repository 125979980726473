<template>
  <div class="LoyiEmpSelect" :style="`width:${width}px;margin-left:-${width/2}px`" @click.stop>
    <div class="LoyiEmpSelect_title">
      <div class="LoyiEmpSelect_title_1">{{EmpData.item.itemName}} × {{EmpData.item.num}} （{{EmpData.item.percentagePlan==2?'限定总额':'自由分配'}}）</div>
    </div>
    <div class="LoyiEmpSelect_a">
      <div class="LoyiEmpSelect_a_1">
        <ul>
          <li :class="jibieNum==0?'hover':''" @click="addjibie(0)">全部</li>
          <li :class="jibieNum==item.jibieId?'hover':''" v-for="item in jibieList" :key="item.jibieId" @click="addjibie(item.jibieId)">{{item.jibieName}}</li>
        </ul>
      </div>
      <div class="LoyiEmpSelect_a_2">
        <div class="LoyiEmpSelect_a_2_scroll">
          <div class="LoyiEmpSelect_a_2_1" v-for="item in empList" :key="item.id" @click="addRenyuan(item)">
            <div class="LoyiEmpSelect_a_2_1_1">{{item.name}}</div>
            <div class="LoyiEmpSelect_a_2_1_1">{{item.jibieName}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="LoyiEmpSelect_b">
      <div class="LoyiEmpSelect_b_1">
        <div class="LoyiEmpSelect_b_1_scroll">
          <table>
            <colgroup>
              <col width="80">
              <col width="65">
            </colgroup>
            <tr v-if="EmpData.item.percentagePlan==2" class="xiandingzonge">
              <td>&nbsp;&nbsp;限定总额:</td>
              <td><strong></strong></td>
              <td>{{EmpData.item.allBFB}}%</td>
              <td>{{EmpData.item.allGD}}元</td>
              <td></td>
            </tr>
            <tr v-for="(item,i) in renyuanList" :key="item.title">
              <td><label v-if="!EmpData.item.product">指 <input type="checkbox" v-model="item.zhiding" @change="addZhiding(item)"></label>&nbsp;&nbsp;{{item.title}}:</td>
              <td><strong :class="item.id?'succsuc':'impor'">{{item.id?item.name:'未选择'}}</strong></td>
              <td><input type="number" v-model="item.baifenbi"> %</td>
              <td><input type="number" v-model="item.guding"> 元</td>
              <td><button type="button" class="err" @click="clearRen(item,i)">删除</button></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LoyiEmpSelect',
  props: {
    width:{
      type:Number,
      default:600,
    },
    EmpData:Object,
  },
  data () {
    return {
      empList:[],
      jibieList:[],
      jibieNum:0,
      renyuanList:[],
      shareList:[],
    }
  },
  created () {
    this.init()
  },
  mounted () {
    document.addEventListener('click',this.closeLoyiEmpSelect)
  },
  methods: {
    addZhiding(item){
      if(!this.EmpData.item.product&&item.id){
        const {tichengbaifenbi,tichengguding}=this.shareList.find(e=>e.id==item.jibieId)
        item.zhidingjiacheng={tichengbaifenbi,tichengguding}
        if(item.zhiding){
          item.baifenbi+=tichengbaifenbi
          item.guding+=tichengguding
        }else{
          item.baifenbi-=tichengbaifenbi
          item.guding-=tichengguding
        }
      }
    },
    clearRen(ren,num){
      if(num>2){
        this.renyuanList.splice(num,1)
      }else{
        ren.id=null
        ren.name=null
        ren.jibieId=null
        ren.jibieName=null
        if(ren.zhidingjiacheng&&!this.EmpData.item.product&&ren.zhiding){
          ren.baifenbi-=ren.zhidingjiacheng.tichengbaifenbi
          ren.guding-=ren.zhidingjiacheng.tichengguding
        }
        ren.zhiding=false
      }
    },
    addRenyuan(item){
      let ren=this.renyuanList.find(e=>!e.id)
      if(ren){
        ren.name=item.name
        ren.id=item.id
        ren.jibieId=item.jibieId
        ren.jibieName=item.jibieName
        const {tichengbaifenbi,tichengguding}=this.shareList.find(e=>e.id==item.jibieId)
        ren.zhidingjiacheng={tichengbaifenbi,tichengguding}
        if(!this.EmpData.item.product&&ren.zhiding&&ren.zhidingjiacheng){
          const {tichengbaifenbi,tichengguding}=ren.zhidingjiacheng
          ren.baifenbi+=tichengbaifenbi
          ren.guding+=tichengguding
        }
      }else{
        this.renyuanList.push({
          title:"次服",
          baifenbi:0,
          guding:0,
          defbfb:0,
          defgd:0,
          zhiding:false,
          ...item,
        })
      }
    },
    closeLoyiEmpSelect(){
      let arr=this.renyuanList.filter(e=>e.title!="次服"||(e.id&&(e.baifenbi>0||e.guding>0)))
      arr.forEach(e=>{
        if(e.zhiding){
          e.isOrigin=e.baifenbi==e.defbfb+e.zhidingjiacheng.tichengbaifenbi?e.guding==e.defgd+e.zhidingjiacheng.tichengguding?0:1:1
        }else{
          e.isOrigin=e.baifenbi==e.defbfb?e.guding==e.defgd?0:1:1
        }
      })
      this.$emit('resetData',arr)
      this.$emit("closeLoyiEmpSelect")
    },
    init(){
      this.empList=this.EmpData.empList
      this.jibieList=this.EmpData.jibieList
      this.renyuanList=this.EmpData.item.renyuanList
      this.shareList=this.EmpData.shareList
      if(this.renyuanList.length==0){
        this.renyuanList.push({
          title:"主服",
          name:'',
          defbfb:this.EmpData.item.onebaifenbi||0,
          defgd:this.EmpData.item.oneguding||0,
          baifenbi:this.EmpData.item.onebaifenbi||0,
          guding:this.EmpData.item.oneguding||0,
          zhiding:false,
        },{
          title:"副服",
          name:'',
          defbfb:this.EmpData.item.twobaifenbi||0,
          defgd:this.EmpData.item.twoguding||0,
          baifenbi:this.EmpData.item.twobaifenbi||0,
          guding:this.EmpData.item.twoguding||0,
          zhiding:false,
        },{
          title:"从服",
          name:'',
          defbfb:this.EmpData.item.threebaifenbi||0,
          defgd:this.EmpData.item.threeguding||0,
          baifenbi:this.EmpData.item.threebaifenbi||0,
          guding:this.EmpData.item.threeguding||0,
          zhiding:false,
        })
      }
    },
    addjibie(num){
      if(num==0) {
        this.empList=this.EmpData.empList
      }else{
        this.empList=this.EmpData.empList.filter(e=>e.jibieId==num)
      }
      this.jibieNum=num
    },

  },
  unmounted(){
    document.removeEventListener("click",this.closeLoyiEmpSelect)
  }
}
</script>
